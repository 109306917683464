/* eslint-disable prettier/prettier */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, message, Popconfirm, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

function ProductRegister() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id: editId } = useParams();

  const [loading, setLoading] = useState(false);
  const [screenModified, setScreenModified] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();

  const fetchProductEdit = async (id) => {
    setLoading(true);

    const res = await api
      .get(`/Product?id=${id}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados!');
      });

    form.setFieldsValue(res);
    setLoading(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    await api
      .put('/Product', values)
      .then(() => {
        message.success(`Dados atualizados!`);
        form.resetFields();

        if (editId) {
          fetchProductEdit(editId);
        }

        setLoading(false);
        setScreenModified(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Erro ao enviar os dados do Produto!');
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'Product') {
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  useEffect(() => {
    if (editId) {
      fetchProductEdit(editId);
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      onChange={() => setScreenModified(true)}
      style={{ maxWidth: '100%', margin: '0 auto' }}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}
        <Button loading={loading} block type="primary" htmlType="submit">
          Salvar
        </Button>
      </div>
      {/* Campos invisiveis */}
      <Form.Item className="input-hidden" label="Id" name="id">
        <Input />
      </Form.Item>
      <Form.Item className="input-hidden" label="IdBitrix" name="idBitrixProduct">
        <Input />
      </Form.Item>

      <Row gutter={[24]}>
        {fieldsPermissions?.Name.access !== 0 && (
          <Col span={12}>
            <Form.Item label="Produto" name="name">
              <Input disabled />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Level.access !== 0 && (
          <Col span={6}>
            <Form.Item label="Nível" name="level">
              <Input placeholder="Intermediário..." />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Workload.access !== 0 && (
          <Col span={3}>
            <Form.Item label="Carga Horária" name="workload">
              <Input placeholder="0h" />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Validity.access !== 0 && (
          <Col span={3}>
            <Form.Item label="Validade (meses)" name="validity">
              <InputNumber min={0} max={99} placeholder="0" />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Description.access !== 0 && (
          <Col span={12}>
            <Form.Item label="Descrição" name="description">
              <Input.TextArea rows={6} placeholder="Digite aqui..." disabled />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Sayings.access !== 0 && (
          <Col span={12}>
            <Form.Item label="Dizeres" name="sayings">
              <Input.TextArea rows={6} placeholder="Digite aqui..." />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.SayingsCompany.access !== 0 && (
          <Col span={12}>
            <Form.Item label="Dizeres Empresa" name="sayingsCompany">
              <Input.TextArea rows={6} placeholder="Digite aqui..." />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.SayingsAttestation.access !== 0 && (
          <Col span={12}>
            <Form.Item label="Dizeres Atestado" name="sayingsAttestation">
              <Input.TextArea rows={6} placeholder="Digite aqui..." />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.SayingsCard.access !== 0 && (
          <Col span={12}>
            <Form.Item label="Dizeres Carteirinha" name="sayingsCard">
              <Input.TextArea rows={6} placeholder="Digite aqui..." />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.ProgramContent.access !== 0 && (
          <Col span={12}>
            <Form.Item label="Conteúdo Progamático" name="programContent">
              <Input.TextArea rows={6} placeholder="Digite aqui..." />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default ProductRegister;
