/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Col, Divider, Form, Input, Modal, Row } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import Placeholder from './Placeholder';

import '../../TaskList/Components/ModalStyle.scss';

function CertificateFieldsModal({ setIsVisible }) {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (element) => {
    const text = element.target.value;
    setSearchText(text.toLowerCase());
  };

  return (
    <Modal
      title="Mapeamento de Campos"
      open={true}
      width="70%"
      className="training-list-columns-settings-modal"
      onCancel={() => setIsVisible(false)}
      footer={[]}
    >
      <Row gutter={[24]} style={{ width: '100%' }}>
        <Col span={8}>
          <Form.Item key="search">
            <Input
              placeholder="Encontrar campo"
              onChange={handleSearch}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider className="configuration-divider" orientation="left">
        Treinamento
      </Divider>

      {/* All Task Fields */}
      <Row gutter={[24]}>
        {/* Ordered Alphabetically */}
        <Placeholder
          label="Aceita Treinamento Compartilhado"
          name="{{Task.AcceptSharedTraining}}"
          searchText={searchText}
        />
        <Placeholder
          label="Observações Comercial"
          name="{{Task.CommercialNotes}}"
          searchText={searchText}
        />
        <Placeholder label="Observações CT" name="{{Task.CtNotes}}" searchText={searchText} />
        <Placeholder
          label="Observações Instrutor"
          name="{{Task.InstructorNotes}}"
          searchText={searchText}
        />
        <Placeholder
          label="Observações Logística"
          name="{{Task.LogisticsNotes}}"
          searchText={searchText}
        />
        <Placeholder
          label="Observações do Negócio"
          name="{{Task.DealNotes}}"
          searchText={searchText}
        />
        <Placeholder label="Bairro" name="{{Task.DistrictLogistic}}" searchText={searchText} />
        <Placeholder label="CEP" name="{{Task.PostalCodeLogistic}}" searchText={searchText} />
        <Placeholder
          label="Centro de Treinamento - Id"
          name="{{Task.TrainingCenterId}}"
          searchText={searchText}
        />
        <Placeholder
          label="Centro de Treinamento - Nome"
          name="{{Task.TrainingCenter}}"
          searchText={searchText}
        />
        <Placeholder label="Cidade" name="{{Task.CityLogistic}}" searchText={searchText} />
        <Placeholder label="Cliente Id" name="{{Task.DealClientId}}" searchText={searchText} />
        <Placeholder
          label="Cliente Prioritário"
          name="{{Task.DealPrioritaryClient}}"
          searchText={searchText}
        />
        <Placeholder
          label="CNPJ do Cliente"
          name="{{Task.DealClientCNPJ}}"
          searchText={searchText}
        />
        <Placeholder
          label="Complemento"
          name="{{Task.ComplementLogistic}}"
          searchText={searchText}
        />
        <Placeholder
          label="Condição de Pagamento"
          name="{{Task.DealPaymentCondition}}"
          searchText={searchText}
        />
        <Placeholder
          label="Condição de Pagamento Negociada"
          name="{{Task.DealPaymentConditionNegotiated}}"
          searchText={searchText}
        />
        <Placeholder label="Data" name="{{Task.StartDate}}" searchText={searchText} />
        <Placeholder
          label="Data de Agendamento"
          name="{{Task.DateScheduling}}"
          searchText={searchText}
        />
        <Placeholder
          label="Data de Confirmação"
          name="{{Task.ConfirmationDate}}"
          searchText={searchText}
        />
        <Placeholder
          label="Data de Emissão da Nota Fiscal"
          name="{{Task.InvoiceEmissionDate}}"
          searchText={searchText}
        />
        <Placeholder
          label="Data de Upload do Termo de Aceite"
          name="{{Task.DateUploadAcceptTerm2}}"
          searchText={searchText}
        />
        <Placeholder
          label="Data Final da Nota Fiscal"
          name="{{Task.DealEndDateInvoice}}"
          searchText={searchText}
        />
        <Placeholder
          label="Data Limite de Faturamento"
          name="{{Task.BillingDeadlineDate}}"
          searchText={searchText}
        />
        <Placeholder label="Dias EAD" name="{{Task.EadDays}}" searchText={searchText} />
        <Placeholder
          label="Dias In Company por Turma"
          name="{{Task.InCompanyDaysPerClass}}"
          searchText={searchText}
        />
        <Placeholder
          label="Dias Práticos por Turma"
          name="{{Task.PracticalDaysPerClass}}"
          searchText={searchText}
        />
        <Placeholder
          label="Dias Teóricos por Turma"
          name="{{Task.TheoreticalDaysPerClass}}"
          searchText={searchText}
        />
        <Placeholder
          label="Documento Enviado"
          name="{{Task.HasDocumentation}}"
          searchText={searchText}
        />
        <Placeholder
          label="Download do Pedido de Compra"
          name="{{Task.DownloadDealBuyOrder}}"
          searchText={searchText}
        />
        <Placeholder
          label="Download do Termo de Aceite"
          name="{{Task.DownloadAcceptTerm1}}"
          searchText={searchText}
        />
        <Placeholder
          label="Download do Termo de Aceite Assinado"
          name="{{Task.DownloadAcceptTerm2}}"
          searchText={searchText}
        />
        <Placeholder
          label="Email do Responsável"
          name="{{Task.ResponsiblePersonEmailLogistic}}"
          searchText={searchText}
        />
        <Placeholder
          label="Email do Responsável pela Confirmação"
          name="{{Task.EmailResponsibleConfirmation}}"
          searchText={searchText}
        />
        <Placeholder
          label="Empresa Contratada"
          name="{{Task.DealContractedCompany}}"
          searchText={searchText}
        />
        <Placeholder
          label="Emissor da Nota Fiscal - Id"
          name="{{Task.InvoiceIssuer.Id}}"
          searchText={searchText}
        />
        <Placeholder
          label="Emissor da Nota Fiscal - Nome"
          name="{{Task.InvoiceIssuer.Name}}"
          searchText={searchText}
        />
        <Placeholder
          label="Endereço do Centro de Treinamento"
          name="{{Task.DealTrainingCenterAddress}}"
          searchText={searchText}
        />
        <Placeholder label="Estado" name="{{Task.StateLogistic}}" searchText={searchText} />
        <Placeholder label="Estágio - Id" name="{{Task.Stage.Id}}" searchText={searchText} />
        <Placeholder label="Estágio - Nome" name="{{Task.Stage.Name}}" searchText={searchText} />
        <Placeholder
          label="Forma de Pagamento"
          name="{{Task.DealPaymentForm}}"
          searchText={searchText}
        />
        <Placeholder
          label="Forma de Pagamento Negociada"
          name="{{Task.DealPaymentFormNegotiated}}"
          searchText={searchText}
        />
        <Placeholder
          label="Horário de Embarque"
          name="{{Task.BoardingHour}}"
          searchText={searchText}
        />
        <Placeholder label="Horário Início" name="{{Task.StartHour}}" searchText={searchText} />
        <Placeholder label="Id da Turma" name="{{Task.SlotClassId}}" searchText={searchText} />
        <Placeholder label="Id do Negócio" name="{{Task.DealId}}" searchText={searchText} />
        <Placeholder label="Id do Serviço" name="{{Task.ServiceId}}" searchText={searchText} />
        <Placeholder
          label="Id do Usuário de Confirmação"
          name="{{Task.IdConfirmedBy}}"
          searchText={searchText}
        />
        <Placeholder
          label="Id do Usuário de Responsável pela Confirmação"
          name="{{Task.QuoteResponsibleId}}"
          searchText={searchText}
        />
        <Placeholder
          label="Instrutor Auxiliar - Estágio - Id"
          name="{{Task.AssistantInstructor.Stage.Id}}"
          searchText={searchText}
        />
        <Placeholder
          label="Instrutor Auxiliar - Estágio - Nome"
          name="{{Task.AssistantInstructor.Stage.Name}}"
          searchText={searchText}
        />
        <Placeholder
          label="Instrutor Auxiliar - Id"
          name="{{Task.AssistantInstructor.UserId}}"
          searchText={searchText}
        />
        <Placeholder
          label="Instrutor Auxiliar - Nome"
          name="{{Task.AssistantInstructor.UserName}}"
          searchText={searchText}
        />
        <Placeholder
          label="Instrutor Estágio - Id"
          name="{{Task.MainInstructor.Stage.Id}}"
          searchText={searchText}
        />
        <Placeholder
          label="Instrutor Estágio - Nome"
          name="{{Task.MainInstructor.Stage.Name}}"
          searchText={searchText}
        />
        <Placeholder
          label="Lista de Presença Final - URL"
          name="{{Task.FinalAttendanceDocList.Url}}"
          searchText={searchText}
        />
        <Placeholder
          label="Lista de Presença Inicial - URL"
          name="{{Task.InitialAttendanceDocList.Url}}"
          searchText={searchText}
        />
        <Placeholder
          label="Lista de Presença Verificada"
          name="{{Task.AttendanceListChecked}}"
          searchText={searchText}
        />

        <Placeholder
          label="Motorista Estágio - Id"
          name="{{Task.DriverList.Stage.Id}}"
          searchText={searchText}
        />
        <Placeholder
          label="Motorista Estágio - Nome"
          name="{{Task.DriverList.Stage.Name}}"
          searchText={searchText}
        />
        <Placeholder
          label="Motorista Id"
          name="{{Task.DriverList.UserId}}"
          searchText={searchText}
        />
        <Placeholder
          label="Motorista Nome"
          name="{{Task.DriverList.UserName}}"
          searchText={searchText}
        />
        <Placeholder
          label="NF Treinamento - URL"
          name="{{Task.NFTraining.Url}}"
          searchText={searchText}
        />
        <Placeholder
          label="NF Transporte - URL"
          name="{{Task.NFTransportation.Url}}"
          searchText={searchText}
        />
        <Placeholder
          label="Não Possui Certificado"
          name="{{Task.NotHaveCertificate}}"
          searchText={searchText}
        />
        <Placeholder label="Nome do Cliente" name="{{Task.DealClient}}" searchText={searchText} />
        <Placeholder
          label="Nome do Cliente Fantasia"
          name="{{Task.DealClientCommercialName}}"
          searchText={searchText}
        />
        <Placeholder
          label="Nome do Pedido de Compra"
          name="{{Task.DealBuyOrder}}"
          searchText={searchText}
        />
        <Placeholder
          label="Nome do Responsável"
          name="{{Task.ResponsiblePersonNameLogistic}}"
          searchText={searchText}
        />
        <Placeholder
          label="Nome do Responsável pela Confirmação"
          name="{{Task.NameResponsibleConfirmation}}"
          searchText={searchText}
        />
        <Placeholder label="Número" name="{{Task.NumberLogistic}}" searchText={searchText} />
        <Placeholder
          label="Número do Pedido de Compra"
          name="{{Task.DealBuyOrderNumber}}"
          searchText={searchText}
        />
        <Placeholder
          label="Observação do Cliente"
          name="{{Task.DealClientObservation}}"
          searchText={searchText}
        />
        <Placeholder label="Observações" name="{{Task.Note}}" searchText={searchText} />
        <Placeholder
          label="Pedido de Compra Obrigatório"
          name="{{Task.ObrigatoryBuyOrder}}"
          searchText={searchText}
        />
        <Placeholder
          label="Produto Aceito"
          name="{{Task.AcceptedProduct}}"
          searchText={searchText}
        />
        <Placeholder
          label="Quantidade de Alunos por Turma"
          name="{{Task.QtyStudentsPerClass}}"
          searchText={searchText}
        />
        <Placeholder
          label="Quantidade de Alunos Transportados"
          name="{{Task.QtyStudentsTransported}}"
          searchText={searchText}
        />
        <Placeholder
          label="Quantidade de Alunos Treinados"
          name="{{Task.QtyStudentsTrained}}"
          searchText={searchText}
        />
        <Placeholder
          label="Quantidade de Turmas"
          name="{{Task.QtyClass}}"
          searchText={searchText}
        />
        <Placeholder
          label="Razão para Não Faturamento - Id"
          name="{{Task.ReasonNoBilling.Id}}"
          searchText={searchText}
        />
        <Placeholder
          label="Razão para Não Faturamento - Nome"
          name="{{Task.ReasonNoBilling.Name}}"
          searchText={searchText}
        />
        <Placeholder
          label="Retenção Tributária"
          name="{{Task.DealTributaryRetention}}"
          searchText={searchText}
        />
        <Placeholder label="Rua" name="{{Task.StreetLogistic}}" searchText={searchText} />
        <Placeholder
          label="Telefone do Responsável"
          name="{{Task.ResponsiblePersonPhoneLogistic}}"
          searchText={searchText}
        />
        <Placeholder
          label="Telefone do Responsável pela Confirmação"
          name="{{Task.PhoneResponsibleConfirmation}}"
          searchText={searchText}
        />
        <Placeholder label="Termo de Aceite" name="{{Task.AcceptTerm1}}" searchText={searchText} />
        <Placeholder
          label="Termo de Aceite Assinado"
          name="{{Task.AcceptTerm2}}"
          searchText={searchText}
        />
        <Placeholder
          label="Tipo de Veículo - Id"
          name="{{Task.VehicleList.Type.Id}}"
          searchText={searchText}
        />
        <Placeholder
          label="Tipo de Veículo - Nome"
          name="{{Task.VehicleList.Type.Name}}"
          searchText={searchText}
        />
        <Placeholder label="Título do Negócio" name="{{Task.DealTitle}}" searchText={searchText} />
        <Placeholder
          label="Título do Treinamento"
          name="{{Task.CardTitle}}"
          searchText={searchText}
        />
        <Placeholder
          label="Treinamento Compartilhado"
          name="{{Task.SharedTraining}}"
          searchText={searchText}
        />
        <Placeholder label="Veículo Ano" name="{{Task.VehicleList.Year}}" searchText={searchText} />
        <Placeholder
          label="Veículo Capacidade"
          name="{{Task.VehicleList.Capacity}}"
          searchText={searchText}
        />
        <Placeholder
          label="Veículo Chassi"
          name="{{Task.VehicleList.Chassis}}"
          searchText={searchText}
        />
        <Placeholder
          label="Veículo Cor"
          name="{{Task.VehicleList.Color}}"
          searchText={searchText}
        />
        <Placeholder label="Veículo Id" name="{{Task.VehicleList.Id}}" searchText={searchText} />
        <Placeholder
          label="Veículo Modelo"
          name="{{Task.VehicleList.Model}}"
          searchText={searchText}
        />
        <Placeholder
          label="Veículo Placa"
          name="{{Task.VehicleList.LicensePlate}}"
          searchText={searchText}
        />
        <Placeholder
          label="Valor da Oportunidade"
          name="{{Task.DealOportunity}}"
          searchText={searchText}
        />
        <Placeholder label="Valor de Reembolso" name="{{Task.Refund}}" searchText={searchText} />
        <Placeholder
          label="Valor do Instrutor"
          name="{{Task.InstructorValue}}"
          searchText={searchText}
        />
        <Placeholder
          label="Valor do Instrutor Auxiliar"
          name="{{Task.AssistantInstructorValue}}"
          searchText={searchText}
        />
        <Placeholder
          label="Valor do Transporte"
          name="{{Task.TransportValue}}"
          searchText={searchText}
        />
        <Placeholder
          label="Valor do Treinamento"
          name="{{Task.TrainingValue}}"
          searchText={searchText}
        />
        <Placeholder
          label="Valor Pago pelo Transporte"
          name="{{Task.AmountPaidTransportation}}"
          searchText={searchText}
        />
        <Placeholder
          label="Valor Parcial da Nota Fiscal"
          name="{{Task.PartialInvoiceValue}}"
          searchText={searchText}
        />
        <Placeholder
          label="Valor Total da Nota Fiscal"
          name="{{Task.TotalInvoiceValue}}"
          searchText={searchText}
        />
      </Row>

      <Divider className="configuration-divider" orientation="left">
        Produto/Configuração
      </Divider>

      <Row gutter={[24]}>
        <Placeholder label="Produto: Id" name="{{Task.Product.Id}}" searchText={searchText} />
        <Placeholder label="Produto: Nome" name="{{Task.Product.Name}}" searchText={searchText} />
        <Placeholder
          label="Produto: Complemento"
          name="{{Task.Product.Complement}}"
          searchText={searchText}
        />
        <Placeholder label="Nível" name="{{Task.CertificateLevel}}" searchText={searchText} />
        <Placeholder
          label="Carga Horária"
          name="{{Task.CertificateWorkload}}"
          searchText={searchText}
        />
        <Placeholder label="Validade" name="{{Task.CertificateValidity}}" searchText={searchText} />
        <Placeholder
          label="Data de Expiração"
          name="{{Task.CertificateExpirationDate}}"
          searchText={searchText}
        />
        <Placeholder
          label="Conteúdo Programático"
          name="{{Task.CertificateProgramContent}}"
          searchText={searchText}
        />
        <Placeholder label="Dizeres" name="{{Task.CertificateSayings}}" searchText={searchText} />
        <Placeholder
          label="Dizeres Empresa"
          name="{{Task.CertificateSayingsCompany}}"
          searchText={searchText}
        />
        <Placeholder
          label="Dizeres Atestado"
          name="{{Task.CertificateSayingsAttestation}}"
          searchText={searchText}
        />
        <Placeholder
          label="Dizeres Carteirinha"
          name="{{Task.CertificateSayingsCard}}"
          searchText={searchText}
        />
        <Placeholder
          label="Tipo de Modelo"
          name="{{Task.CertificateModel.ModelType}}"
          searchText={searchText}
        />
        <Placeholder
          label="Modelo: Id"
          name="{{Task.CertificateModel.Id}}"
          searchText={searchText}
        />
        <Placeholder
          label="Modelo: Nome"
          name="{{Task.CertificateModel.Label}}"
          searchText={searchText}
        />
        <Placeholder
          label="Modelo: URL"
          name="{{Task.CertificateModel.Url}}"
          searchText={searchText}
        />
      </Row>

      <Divider className="configuration-divider" orientation="left">
        Aluno
      </Divider>

      <Row gutter={[24]}>
        <Placeholder label="Nome" name="{{Student.Name}}" searchText={searchText} />
        <Placeholder label="Email" name="{{Student.Email}}" searchText={searchText} />
        <Placeholder label="Telefone" name="{{Student.Phone}}" searchText={searchText} />
        <Placeholder label="CPF" name="{{Student.Cpf}}" searchText={searchText} />
        <Placeholder label="RG" name="{{Student.Rg}}" searchText={searchText} />
        <Placeholder
          label="Data de Nascimento"
          name="{{Student.BirthDate}}"
          searchText={searchText}
        />
        <Placeholder label="CNPJ" name="{{Student.Cnpj}}" searchText={searchText} />
        <Placeholder label="Endereço" name="{{Student.Address}}" searchText={searchText} />
        <Placeholder label="Nome Fantasia" name="{{Student.CompanyName}}" searchText={searchText} />
      </Row>

      <Divider className="configuration-divider" orientation="left">
        Lista de Alunos
      </Divider>

      <Row gutter={[24]}>
        <Placeholder label="Nome" name="{{Task.QueryStudentList.Name}}" searchText={searchText} />
        <Placeholder label="Email" name="{{Task.QueryStudentList.Email}}" searchText={searchText} />
        <Placeholder
          label="Telefone"
          name="{{Task.QueryStudentList.Phone}}"
          searchText={searchText}
        />
        <Placeholder label="CPF" name="{{Task.QueryStudentList.Cpf}}" searchText={searchText} />
        <Placeholder label="RG" name="{{Task.QueryStudentList.Rg}}" searchText={searchText} />
        <Placeholder
          label="Data de Nascimento"
          name="{{Task.QueryStudentList.BirthDate}}"
          searchText={searchText}
        />
        <Placeholder label="CNPJ" name="{{Task.QueryStudentList.Cnpj}}" searchText={searchText} />
        <Placeholder
          label="Endereço"
          name="{{Task.QueryStudentList.Address}}"
          searchText={searchText}
        />
        <Placeholder
          label="Nome Fantasia"
          name="{{Task.QueryStudentList.CompanyName}}"
          searchText={searchText}
        />
      </Row>

      <Divider className="configuration-divider" orientation="left">
        Instrutor
      </Divider>

      <Row gutter={[24]}>
        <Placeholder label="Id" name="{{Task.MainInstructor.UserId}}" searchText={searchText} />
        <Placeholder label="Nome" name="{{Task.MainInstructor.UserName}}" searchText={searchText} />
        <Placeholder label="CPF" name="{{Instructor.CPF}}" searchText={searchText} />
        <Placeholder label="RG" name="{{Instructor.RG}}" searchText={searchText} />
        <Placeholder
          label="Data de Nascimento"
          name="{{Instructor.BirthDate}}"
          searchText={searchText}
        />
        <Placeholder label="Rua" name="{{Instructor.PersonalStreet}}" searchText={searchText} />
        <Placeholder
          label="Complemento"
          name="{{Instructor.PersonalComplement}}"
          searchText={searchText}
        />
        <Placeholder label="CEP" name="{{Instructor.PersonalCep}}" searchText={searchText} />
        <Placeholder
          label="Bairro"
          name="{{Instructor.PersonalDistrict}}"
          searchText={searchText}
        />
        <Placeholder label="Cidade" name="{{Instructor.PersonalCity}}" searchText={searchText} />
        <Placeholder label="Estado" name="{{Instructor.PersonalState}}" searchText={searchText} />
        <Placeholder
          label="Formação profissional"
          name="{{Instructor.Qualification}}"
          searchText={searchText}
        />
        <Placeholder label="Carro: Modelo" name="{{Instructor.CarModel}}" searchText={searchText} />
        <Placeholder label="Carro: Cor" name="{{Instructor.CarColor}}" searchText={searchText} />
        <Placeholder
          label="Carro: Placa"
          name="{{Instructor.CarLicensePlate}}"
          searchText={searchText}
        />
        <Placeholder
          label="Inscrição municipal"
          name="{{Instructor.MunicipalRegistration}}"
          searchText={searchText}
        />
        <Placeholder label="Banco" name="{{Instructor.Bank}}" searchText={searchText} />
        <Placeholder label="Agência" name="{{Instructor.Agency}}" searchText={searchText} />
        <Placeholder
          label="Conta Corrente"
          name="{{Instructor.TransactionAccount}}"
          searchText={searchText}
        />
        <Placeholder label="Chave PIX" name="{{Instructor.PIX}}" searchText={searchText} />
        <Placeholder
          label="Observações"
          name="[[Instructor.DescriptionInstructor}}"
          searchText={searchText}
        />
        <Placeholder label="Assinatura" name="[[Instructor.Signature]]" searchText={searchText} />
      </Row>
    </Modal>
  );
}

export default CertificateFieldsModal;
