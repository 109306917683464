import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import { Col, message, Row, Spin, Tooltip } from 'antd';
import { Scheduler } from 'devextreme-react';
import { Resource } from 'devextreme-react/scheduler';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import OverviewCell from '../../Components/Schedule/OverviewCell';
import OverviewPopup from '../../Components/Schedule/OverviewPopup';
import ResumePopup from '../../Components/Schedule/ResumePopup';
import { api } from '../../Services/axiosService';
import SchedulingFunctions from '../ScheduleConecta/API/SchedulingFunctions';

import {
  addOverviewScheduleFilter,
  fetchOverviewScheduleFilter,
  updateOverviewScheduleFilter,
} from './API/ScheduleOverviewAPI';

import './ScheduleOverview.scss';

let timeoutCell = null;
let currentCellGroup = null;
let currentCellDate = null;
let isFirstRun = true;
let firstOpen = 0;

const views = [
  {
    name: 'Dia',
    type: 'timelineDay',
    groupOrientation: 'vertical',
    cellDuration: 1440,
  },
  {
    name: 'Semana',
    type: 'timelineWeek',
    groupOrientation: 'vertical',
    cellDuration: 1440,
  },
  {
    name: 'Mês',
    type: 'timelineMonth',
    groupOrientation: 'vertical',
    cellDuration: 1440,
  },
];

const notifyNoneTrainingSelected = () => {
  message.warning('Nenhum Tipo de Treinamento selecionado!');
};

const notifyNoneCtSelected = () => {
  message.warning('Nenhum Centro de Treinamento selecionado!');
};

let timeoutFilter = null;

function ScheduleOverviewComponent() {
  const navigate = useNavigate();
  const schedulerRef = useRef(null);
  const resumePopupRef = useRef(null);
  const overviewPopupRef = useRef(null);

  const groups = ['trainingCenterId'];

  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState('timelineWeek');
  const [currentDate, setCurrentDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [trainingCenters, setTrainingCenters] = useState();
  const [tasks, setTasks] = useState();
  const [trainingsValue, setTrainingsValue] = useState(['CT']);
  const [blockedDays, setBlockedDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [selectedTrainingCenterIds, setSelectedTrainingCenterIds] = useState([]);
  const [filteredCts, setfilteredCts] = useState();

  // Filtros
  const [overviewScheduleFilter, setOverviewScheduleFilter] = useState();

  const fetchResumeOverview = async () => {
    const currentStartDate = schedulerRef.current.instance.getStartViewDate();
    const currentEndDate = schedulerRef.current.instance.getEndViewDate();
    const startDate = moment(currentStartDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
    const endDate = moment(currentEndDate.setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');

    const startDateA = moment(currentStartDate).format('YYYY-MM-DD');
    const endDateA = moment(currentEndDate).format('YYYY-MM-DD');

    const response = await api
      .get(
        `/Schedule/ResumeOverviewData?start=${startDateA}&finish=${endDateA}&trainings=${trainingsValue?.toString()}&ctIds=${selectedTrainingCenterIds?.toString()}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados de Resumo e Overview!');
      });

    const responseMap = response?.map((item) => ({ ...item, text: item.product.name }));

    const resumeList = [];
    const setResumeList = (list) => {
      resumeList.push(...list);
    };

    const overviewList = [];
    const setOverviewList = (list) => {
      overviewList.push(...list);
    };
    SchedulingFunctions.getOverviewData(responseMap, setOverviewList, startDate, endDate);
    SchedulingFunctions.getResumeData(responseMap, setResumeList, startDate, endDate);

    return { overviewList, resumeList };
  };

  const fetchData = async () => {
    try {
      const [resOverviewScheduleFilter] = await Promise.all([fetchOverviewScheduleFilter()]);

      // Preenche os filtros
      if (resOverviewScheduleFilter) {
        const { filters } = resOverviewScheduleFilter;

        if (filters.currentDate) {
          setCurrentDate(filters.currentDate);
        }

        setCurrentView(filters.currentView);
        setTrainingsValue(filters.trainingTypeList);
        setSelectedTrainingCenterIds(filters.trainingCenterIdList);
      }

      setOverviewScheduleFilter(resOverviewScheduleFilter);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar as opções de filtro!');
    }
  };

  const fetchTrainingCenters = async () => {
    setIsLoading(true);

    const response = await api
      .get(
        '/TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento'
      )
      .then((res) => res.data)
      .catch((error) => {
        setIsLoading(false);
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const mappedCTs = response
      ?.sort((a, b) => a.id - b.id)
      ?.map(
        ({
          id,
          commercialName: text,
          startBusinessHour,
          endBusinessHour,
          workDays,
          maxCapacityPerDay,
          ctQtyColumns,
          ctQtySlots,
          inCompanyQtyColumns,
          inCompanyQtySlots,
          eadQtyColumns,
          eadQtySlots,
          serviceQtyColumns,
          serviceQtySlots,
        }) => ({
          id,
          text,
          startBusinessHour,
          endBusinessHour,
          workDays,
          maxCapacityPerDay,
          ctQtyColumns,
          ctQtySlots,
          inCompanyQtyColumns,
          inCompanyQtySlots,
          eadQtyColumns,
          eadQtySlots,
          serviceQtyColumns,
          serviceQtySlots,
        })
      );
    setTrainingCenters(mappedCTs);
    const mappedCTsId = mappedCTs.map((ct) => ct.id);
    setSelectedTrainingCenterIds(mappedCTsId);

    await fetchData();

    setIsLoading(false);
  };

  const fetchHolidays = (year) =>
    api
      .get(`/Schedule/Holidays?year=${year}`)
      .then(async (res) => {
        const mappedHolidays = res?.data?.map(({ date, name, type }) => {
          const dateFormatted = moment(date).format('YYYY-MM-DD');
          const typeUppercase = type.charAt(0).toUpperCase() + type.slice(1);
          return { date: dateFormatted, name, type: typeUppercase };
        });
        setHolidays(mappedHolidays);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Feriados!');
      });

  const fetchTasks = async (startDate, endDate, selectedCtIds, trainingIds) => {
    setIsLoading(true);

    const filterTrainigIds = trainingIds || trainingsValue;
    const filterCtIds = selectedCtIds || selectedTrainingCenterIds;
    await fetchHolidays(startDate);

    if (filterTrainigIds?.length === 0) {
      notifyNoneTrainingSelected();
      setIsLoading(false);
      return;
    }

    if (filterCtIds?.length === 0) {
      notifyNoneCtSelected();
      setIsLoading(false);
      return;
    }

    const tasks = await api
      .get(
        `/Schedule?start=${startDate}&finish=${endDate}&trainings=${filterTrainigIds?.toString()}&ctIds=${filterCtIds?.toString()}`
      )
      .then((res) => res.data)
      .catch((error) => {
        setIsLoading(false);
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const mappedCts = trainingCenters.filter(({ id }) => filterCtIds.includes(id));
    setfilteredCts(mappedCts);
    setTasks(tasks);
    setIsLoading(false);
  };

  const fetchBlockedDays = () =>
    api
      .get(
        '/BlockedDay?filters[0].Field=TrainingCenterId&filters[0].Condition=DIFFERENT&filters[0].Value=null'
      )
      .then((res) => {
        const blockedDaysByCT = Utils.groupBy(res.data, 'trainingCenterId');
        setBlockedDays(blockedDaysByCT);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Dias Bloqueados!');
      });

  const onCellClick = (e, trainingCenter, date) => {
    if (!timeoutCell) {
      timeoutCell = setTimeout(() => {
        timeoutCell = null;
      }, 300);
    } else if (currentCellGroup === trainingCenter && currentCellDate === date) {
      if (e?.appointmentData?.hasSlot === false) {
        message.warn('Este CT não possui slots configurados!');
        e.cancel = true;
      } else if (e?.appointmentData?.isBlocked === false) {
        const { text, maxCapacityPerDay } = trainingCenters.find(({ id }) => id === trainingCenter);

        localStorage.setItem(
          'conecta__scheduleData',
          JSON.stringify({
            trainingCenterId: currentCellGroup,
            trainingCenter: text,
            date: currentCellDate,
            viewType: 'day',
            maxCapacityPerDay,
            fromOverview: true,
          })
        );

        navigate('/Agenda/Agendamento');
        return;
      } else if (e?.appointmentData?.isBlocked === true) {
        message.warn('Este dia está bloqueado!');
      }
    }
    currentCellGroup = trainingCenter;
    currentCellDate = date;

    e.cancel = true;
    e.event.stopPropagation();
  };

  const handleFilterClear = async () => {
    const ctIds = trainingCenters.map(({ id }) => id);

    const submitData = {};
    submitData.filters = {
      currentView: 'timelineWeek',
      currentDate: null,
      trainingTypeList: ['CT'],
      trainingCenterIdList: ctIds,
    };

    submitData.id = overviewScheduleFilter.id;
    await updateOverviewScheduleFilter(submitData);

    message.success('Filtros limpos!');
    window.location.reload();
  };

  const handleFilterSave = async () => {
    if (timeoutFilter) {
      clearTimeout(timeoutFilter);
    }

    timeoutFilter = setTimeout(async () => {
      if (!schedulerRef?.current?.instance) {
        handleFilterSave();
        return;
      }

      const scheduleInstance = schedulerRef.current.instance;
      const view = scheduleInstance.option('currentView');
      const date = moment(scheduleInstance.option('currentDate')).format('YYYY-MM-DD');

      const submitData = {};
      submitData.filters = {
        currentView: view,
        currentDate: date,
        trainingTypeList: trainingsValue,
        trainingCenterIdList: selectedTrainingCenterIds,
      };

      if (overviewScheduleFilter) {
        submitData.id = overviewScheduleFilter.id;

        updateOverviewScheduleFilter(submitData);
      } else {
        addOverviewScheduleFilter(submitData);

        fetchOverviewScheduleFilter().then((data) => {
          setOverviewScheduleFilter(data);
        });
      }
    }, 1500); // 1,5s
  };

  const appointmentRender = (itemData) => {
    const workDaysByCT = Utils.groupBy(trainingCenters, 'id');

    return (
      <OverviewCell
        itemData={itemData}
        blockedDaysByCT={blockedDays}
        workDaysByCT={workDaysByCT}
        trainingCenters={trainingCenters}
      />
    );
  };

  const dateCellRender = (itemData) => {
    const currentCellDate = moment(itemData.date).format('YYYY-MM-DD');
    const foundHoliday = holidays?.find(({ date }) => date === currentCellDate);

    if (foundHoliday) {
      return (
        <Tooltip title={`${foundHoliday.type}: ${foundHoliday.name}`}>
          <div
            style={{
              color: foundHoliday.type === 'Facultativo' ? '#FC5D20' : '#CA2D2D',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            <span>{`${itemData.text}`}</span>
            <ExclamationCircleOutlined style={{ marginLeft: 2 }} />
          </div>
        </Tooltip>
      );
    }

    return <span>{itemData.text}</span>;
  };

  const onContentReady = async (e) => {
    const view = e.component.option('currentView');
    let toolbar = e.component.option('toolbar');
    const moreMenuItems = [];
    const date = moment(e.component.option('currentDate')).format('YYYY-MM-DD');
    const currentViewChanged = view !== currentView;
    const currentDateChanged = date !== currentDate;
    firstOpen += 1;

    if (firstOpen >= 2) {
      firstOpen = 0;

      moreMenuItems.push(
        {
          name: 'Resumo',
          showPopup: () => resumePopupRef.current?.instance.show(),
          index: 1,
        },
        {
          name: 'Overview',
          showPopup: () => overviewPopupRef.current?.instance.show(),
          index: 2,
        }
      );

      toolbar = [
        {
          location: 'before',
          defaultElement: 'dateNavigator',
        },
        {
          location: 'before',
          widget: 'dxTagBox',
          options: {
            displayExpr: 'label',
            keyExpr: 'value',
            width: 300,
            items: [
              {
                label: 'Marcar/Desmarcar Todos',
                value: 'ALL',
              },
              {
                label: 'CT',
                value: 'CT',
              },
              {
                label: 'In Company',
                value: 'In Company',
              },
              {
                label: 'EAD',
                value: 'EAD',
              },
            ],
            maxDisplayedTags: 3,
            value: trainingsValue ?? ['CT'],
            valueExpr: 'value',
            showSelectionControls: true,
            onSelectionChanged(e) {
              const allValue = 'ALL';
              const currentValues = e.component.option('value');
              const itemsWithoutAll = e.component
                .option('items')
                .map((item) => item.value)
                .filter((v) => v !== allValue);
              const allItemsSelected = itemsWithoutAll.every((item) =>
                currentValues.includes(item)
              );

              // Se "Marcar/Desmarcar Todos" foi adicionado à seleção
              if (e.addedItems.some((item) => item.value === allValue)) {
                // Marca todos os itens
                e.component.option('value', itemsWithoutAll.concat(allValue));
              }
              // Se "Marcar/Desmarcar Todos" foi removido da seleção
              else if (e.removedItems.some((item) => item.value === allValue)) {
                // Desmarca todos os itens
                e.component.option('value', []);
              } else if (allItemsSelected && !currentValues.includes(allValue)) {
                e.component.option('value', currentValues.concat(allValue));
              } else if (!allItemsSelected && currentValues.includes(allValue)) {
                e.component.option(
                  'value',
                  currentValues.filter((v) => v !== allValue)
                );
              }

              handleFilterSave();
            },
            onFocusOut: async (e) => {
              const selectedItems =
                e.component
                  .option('selectedItems')
                  .map(({ value }) => value)
                  .filter((value) => value !== 'ALL') || [];

              setTrainingsValue(selectedItems);

              const startDate = moment(schedulerRef.current.instance.getStartViewDate()).format(
                'YYYY-MM-DD'
              );
              const endDate = moment(schedulerRef.current.instance.getEndViewDate()).format(
                'YYYY-MM-DD'
              );
              firstOpen += 1;
              await fetchTasks(startDate, endDate, null, selectedItems);
            },
          },
        },
        {
          location: 'before',
          widget: 'dxTagBox',
          options: {
            displayExpr: 'text',
            valueExpr: 'id',
            width: 300,
            items: [
              {
                text: 'Marcar/Desmarcar Todos',
                id: 'ALL',
              },
              ...trainingCenters,
            ],
            value: selectedTrainingCenterIds,
            showSelectionControls: true,
            onSelectionChanged(e) {
              const allValue = 'ALL';
              const currentValues = e.component.option('value');
              const itemsWithoutAll = e.component
                .option('items')
                .map(({ id }) => id)
                .filter((v) => v !== allValue);
              const allItemsSelected = itemsWithoutAll.every((id) => currentValues.includes(id));

              if (e.addedItems.some((item) => item.id === allValue)) {
                e.component.option('value', itemsWithoutAll.concat(allValue));
              } else if (e.removedItems.some((item) => item.id === allValue)) {
                e.component.option('value', []);
              } else if (allItemsSelected && !currentValues.includes(allValue)) {
                e.component.option('value', currentValues.concat(allValue));
              } else if (!allItemsSelected && currentValues.includes(allValue)) {
                e.component.option(
                  'value',
                  currentValues.filter((v) => v !== allValue)
                );
              }

              handleFilterSave();
            },
            onFocusOut: async (e) => {
              const selectedItems =
                e.component
                  .option('selectedItems')
                  .map(({ id }) => id)
                  .filter((id) => id !== 'ALL') || [];
              setSelectedTrainingCenterIds(selectedItems);
              const startDate = moment(schedulerRef.current.instance.getStartViewDate()).format(
                'YYYY-MM-DD'
              );
              const endDate = moment(schedulerRef.current.instance.getEndViewDate()).format(
                'YYYY-MM-DD'
              );
              firstOpen += 1;
              await fetchTasks(startDate, endDate, selectedItems);
            },
          },
        },
        {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'clearformat',
            onClick: handleFilterClear,
            disabled: !overviewScheduleFilter,
            title: 'Limpar Filtros',
          },
        },
        {
          location: 'after',
          defaultElement: 'viewSwitcher',
        },
        {
          location: 'after',
          widget: 'dxDropDownButton',
          options: {
            text: 'Mais',
            displayExpr: 'name',
            width: 150,
            stylingMode: 'contained',
            onItemClick: (e) => {
              e.itemData.showPopup();
            },
            items: moreMenuItems.sort((a, b) => a.index - b.index),
          },
        },
      ];
      e.component.option('toolbar', toolbar);
    }

    if ((currentViewChanged || currentDateChanged) && selectedTrainingCenterIds.length > 0) {
      setCurrentView(view);
      setCurrentDate(date);
      const startDate = moment(e.component.getStartViewDate()).format('YYYY-MM-DD');
      const endDate = moment(e.component.getEndViewDate()).format('YYYY-MM-DD');
      await fetchTasks(startDate, endDate);
    }

    if (
      !isFirstRun &&
      (currentViewChanged || currentDateChanged) &&
      selectedTrainingCenterIds.length > 0
    ) {
      handleFilterSave();
    }

    // So executa quando carrega a página a primeira vez
    if (isFirstRun && selectedTrainingCenterIds.length > 0) {
      isFirstRun = false;
      const startDate = moment(e.component.getStartViewDate()).format('YYYY-MM-DD');
      const endDate = moment(e.component.getEndViewDate()).format('YYYY-MM-DD');
      await fetchTasks(startDate, endDate);
    }
  };

  useLayoutEffect(() => {
    isFirstRun = true;
    fetchTrainingCenters();
    setTasks([]);
    fetchBlockedDays();
  }, []);

  if (isLoading || !trainingCenters || !tasks) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!isLoading && trainingCenters && tasks) {
    return (
      <Col span={24}>
        <OverviewPopup
          overviewPopupRef={overviewPopupRef}
          schedulerRef={schedulerRef}
          totalStudents={trainingCenters?.maxCapacityPerDay}
          currentScreen="scheduleOverView"
          fetchResumeOverview={fetchResumeOverview}
        />

        <ResumePopup
          resumePopupRef={resumePopupRef}
          schedulerRef={schedulerRef}
          currentScreen="scheduleOverView"
          fetchResumeOverview={fetchResumeOverview}
        />

        <Scheduler
          id="schedulerOverview"
          ref={schedulerRef}
          views={views}
          groups={groups}
          dataSource={tasks}
          defaultCurrentView={currentView}
          defaultCurrentDate={currentDate}
          crossScrollingEnabled={true}
          showAllDayPanel={false}
          showCurrentTimeIndicator={false}
          firstDayOfWeek={1}
          editing={false}
          appointmentComponent={appointmentRender}
          dateCellRender={dateCellRender}
          onCellClick={(e) => {
            const trainingCenter = e.cellData.groups.trainingCenterId;
            const date = e.cellData.startDate;
            onCellClick(e, trainingCenter, date);
          }}
          onAppointmentClick={(e) => {
            const trainingCenter = e.appointmentData.trainingCenterId;
            const date = e.appointmentData.startDate;
            onCellClick(e, trainingCenter, date);
          }}
          onAppointmentTooltipShowing={(e) => {
            e.cancel = true;
          }}
          onContentReady={onContentReady}
          useDropDownViewSwitcher
        >
          <Resource
            fieldExpr="trainingCenterId"
            allowMultiple={false}
            dataSource={filteredCts ?? trainingCenters}
            label="CT"
          />
        </Scheduler>
      </Col>
    );
  }
}

const ScheduleOverview = memo(ScheduleOverviewComponent);
export default ScheduleOverview;
