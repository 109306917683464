/* eslint-disable prettier/prettier */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, message, Popconfirm, Row } from 'antd';
import Search from 'antd/lib/transfer/search';
import Inputmask from 'inputmask';
import { useNavigate, useParams } from 'react-router-dom';

import CompanyFunctions from '../../../Assets/Scripts/CompanyFunctions';
import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

function CompanyRegister() {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [screenModified, setScreenModified] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();

  const fetchCompanyEdit = async (id) => {
    setLoading(true);

    const res = await api
      .get(`/Company?id=${id}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados!');
      });

    form.setFieldsValue(res);
    setLoading(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const httpMethod = editId ? 'put' : 'post';
    await api[httpMethod]('/Company', values)
      .then(() => {
        message.success(`Dados ${editId ? 'atualizados' : 'salvos'}!`);
        form.resetFields();

        if (editId) {
          fetchCompanyEdit(editId);
        }

        setLoading(false);
        setScreenModified(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Erro ao enviar os dados da Empresa!');
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'Company') {
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  useEffect(() => {
    if (editId) {
      fetchCompanyEdit(editId);
    }

    const maskOptions = { mask: '(99) 9{1,9}' };
    const phone1 = document.getElementById('phone1');
    const phone2 = document.getElementById('phone2');
    const cep = document.getElementById('cep');
    const cnpj = document.getElementById('cnpj');

    if (phone1) Inputmask(maskOptions).mask(phone1);
    if (phone2) Inputmask(maskOptions).mask(phone2);
    if (cep) Inputmask({ mask: '99999-999' }).mask(cep);
    if (cnpj) Inputmask({ mask: '99.999.999/9999-99' }).mask(cnpj);
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      onChange={() => setScreenModified(true)}
      style={{ maxWidth: '100%', margin: '0 auto' }}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}
        <Button loading={loading} block type="primary" htmlType="submit">
          Salvar
        </Button>
      </div>
      {/* Campos invisiveis */}
      <Form.Item className="input-hidden" label="Id" name="id">
        <Input />
      </Form.Item>

      <Row gutter={[24]}>
        {fieldsPermissions?.Cnpj.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="CNPJ"
              name="cnpj"
              rules={[
                {
                  required: fieldsPermissions?.Cnpj.isRequired,
                  validator: (rule, value) => {
                    const isValid = Utils.validateCNPJ(value);
                    if (!isValid && fieldsPermissions?.Cnpj.isRequired) {
                      return Promise.reject(new Error(`CNPJ Inválido`));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Search
                id="cnpj"
                placeholder="00.000.000/0000-00"
                disabled={fieldsPermissions?.Cnpj.access !== 2}
                onSearch={(val) => CompanyFunctions.fetchCnpj(val, form)}
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Name.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: fieldsPermissions?.Name.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.Name.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.CommercialName.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Nome Fantasia"
              name="commercialName"
              rules={[
                {
                  required: fieldsPermissions?.CommercialName.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.CommercialName.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Street.access !== 0 && (
          <Col span={7}>
            <Form.Item
              label="Rua"
              name="street"
              rules={[
                {
                  required: fieldsPermissions?.Street.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.Street.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.AddressNumber.access !== 0 && (
          <Col span={3}>
            <Form.Item
              label="Número"
              name="addressNumber"
              rules={[
                {
                  required: fieldsPermissions?.AddressNumber.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <InputNumber disabled={fieldsPermissions?.AddressNumber.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.District.access !== 0 && (
          <Col span={7}>
            <Form.Item
              label="Bairro"
              name="district"
              rules={[
                {
                  required: fieldsPermissions?.District.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.District.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Cep.access !== 0 && (
          <Col span={7}>
            <Form.Item
              label="CEP"
              name="cep"
              rules={[
                {
                  required: fieldsPermissions?.Cep.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                id="cep"
                placeholder="00000-000"
                disabled={fieldsPermissions?.Cep.access !== 2}
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Complement.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Complemento"
              name="complement"
              rules={[
                {
                  required: fieldsPermissions?.Complement.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.Complement.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.City.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Cidade"
              name="city"
              rules={[
                {
                  required: fieldsPermissions?.City.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.City.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.State.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Estado"
              name="state"
              rules={[
                {
                  required: fieldsPermissions?.State.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.State.access !== 2} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Email.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: fieldsPermissions?.Email.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                placeholder="exemplo@email.com"
                disabled={fieldsPermissions?.Email.access !== 2}
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Phone1.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Telefone 1"
              name="phone1"
              rules={[
                {
                  required: fieldsPermissions?.Phone1.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                id="phone1"
                placeholder="(00) 0000-0000"
                disabled={fieldsPermissions?.Phone1.access !== 2}
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Phone2.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Telefone 2"
              name="phone2"
              rules={[
                {
                  required: fieldsPermissions?.Phone2.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                id="phone2"
                placeholder="(00) 0000-0000"
                disabled={fieldsPermissions?.Phone2.access !== 2}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default CompanyRegister;
