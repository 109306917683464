/* eslint-disable prettier/prettier */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, message, Tooltip } from 'antd';

import { FileExcelOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import ModalUploadExcel from '../../../Components/Instructor/ModalUploadExcel';
import TableAntd from '../../../Components/TableAntd/TableAntd';
import { api } from '../../../Services/axiosService';

export default function InstructorList() {
  const [requiredDocuments, setRequiredDocuments] = useState();
  const [isModalOpen, setIsModalOpen] = useState();
  const [canHandle, setCanHandle] = useState(false);

  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Nome',
        ...getColumnSearchProps('name', 'Nome'),
        sorter: (a, b) => a.name?.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'email',
        title: 'E-mail',
        width: '25%',
        ...getColumnSearchProps('email', 'E-mail'),
        sorter: (a, b) => a.email?.localeCompare(b.email),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'state',
        title: 'Estado',
        ...getColumnSearchProps('state', 'Estado'),
        sorter: (a, b) => a.state?.localeCompare(b.state),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'city',
        title: 'Cidade',
        ...getColumnSearchProps('city', 'Cidade'),
        sorter: (a, b) => a.city?.localeCompare(b.city),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return columns;
  };

  const fetchSettings = async () => {
    const res = await api
      .get(`/Settings?id=1`)
      .then(async (res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os documentos obrigatórios!');
      });

    const decryptedSettings = Utils.decryptSettings(res);
    const reqDocs =
      decryptedSettings.requiredDocumentsInstructor
        ?.split(',')
        ?.map((item) => parseInt(item, 10)) ?? [];
    setRequiredDocuments(reqDocs);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const renderExtraToolbar = () => {
    if (!canHandle) return null;

    return (
      <div style={{ marginLeft: '10px' }}>
        <Tooltip title="Importar Registro">
          <Button
            onClick={openModal}
            shape="circle"
            icon={<FileExcelOutlined />}
            style={{
              width: '100%',
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
          />
        </Tooltip>
      </div>
    );
  };

  useLayoutEffect(() => {
    const vehicleResource = JSON.parse(
      localStorage.getItem('conecta__permissions')
    )?.resources.find(({ name }) => name === 'Instructor');

    setCanHandle(vehicleResource.canHandle);
  }, []);

  useEffect(() => {
    fetchSettings();
  }, []);

  if (requiredDocuments) {
    return (
      <>
        <TableAntd
          columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
          routePermissions="Instructor"
          requestName="Instructor?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Instrutor"
          route="/Instrutor"
          toggleStatusRoute="/Company"
          cdPage="id"
          btnNew
          requiredDocuments={requiredDocuments}
          extraToolbar={renderExtraToolbar()}
        />
        <ModalUploadExcel setIsOpen={setIsModalOpen} isOpen={isModalOpen} screenType="instructor" />
      </>
    );
  }
}
